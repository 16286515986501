import React from "react"
import { Helmet } from "react-helmet-async"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        siteDesc: description
        siteTitle: title
        siteUrl
        author
        email
      }
    }
  }
`
const SEO = ({ title, description, pathname }) => {
  const { site } = useStaticQuery(query)
  const {
    siteDesc,
    siteTitle,
    author,
    email
  } = site.siteMetadata
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description || siteDesc} />
      <meta name="author" content={author} />
      <meta name="email" content={email} />
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
    </Helmet>
  )
}

export default SEO