import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import FormSelect from "./FormSelect";
import { gsap } from "gsap";

const Form = () => {
	const { register, handleSubmit, errors } = useForm();
	const [successMessage, setSuccessMessage] = useState("");
	const [selectedSubject, setSelectedSubject] = useState();
	const [selectedData, setSelectedData] = useState({
		name: "",
		email: "",
		company: "",
		location: "",
		message: "",
	});


	const subjectOptions = [
		{ value: "Becoming a partner", label: "Becoming a partner" },
		{ value: "Consulting and special projects", label: "Consulting and special projects" },
		{ value: "Team building for my company", label: "Team building for my company" },
	];

	const handleSelectedSubject = (e) => {
		setSelectedSubject(e.value);
	};

	const handleData = (e) => {
		const newData = { ...selectedData };
		newData[e.target.name] = e.target.value;
		setSelectedData(newData);
	};

	let formValues = {
		subject: (selectedSubject === undefined) ? "Becoming a partner" : selectedSubject,
		name: selectedData.name,
		email: selectedData.email,
		company: selectedData.company,
		location: selectedData.location,
		message: selectedData.message,
	};

	const onSubmit = (data, e) => {
		e.preventDefault();
		emailjs
			.send(
				"service_zs1itu5",
				"template_ffkoydr",
				formValues,
				"user_JFaWaas8n5m2e2sT3BcWg"
			)
			.then(
				(response) => {
					console.log(response.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		setSelectedData("");
		setSuccessMessage(`Thank you ${data.name} for your message!`);
	};

	let errorMessageNameWrapper = useRef(null);
	let errorMessageName = useRef(null);
	let errorMessageEmailWrapper = useRef(null);
	let errorMessageEmail = useRef(null);
	let thankYouMessageWrapper = useRef(null);
	let thankYouMessage = useRef(null);

	useEffect(() => {
		gsap.from(errorMessageNameWrapper.current, { duration: .5, autoAlpha: 0 });
		gsap.fromTo(
			errorMessageName.current,
			{ opacity: 0 },
			{ duration: 5, opacity: 1 }
		);
	}, []);

	useEffect(() => {
		gsap.from(errorMessageEmailWrapper.current, { duration: .5, autoAlpha: 0 });
		gsap.fromTo(
			errorMessageEmail.current,
			{ opacity: 0 },
			{ duration: 5, opacity: 1 }
		);
	}, []);

	useEffect(() => {
		gsap.from(thankYouMessageWrapper.current, { duration: .5, autoAlpha: 0 });
		gsap.fromTo(
			thankYouMessage.current,
			{ opacity: 0 },
			{ duration: 5, opacity: 1 }
		);
	}, [successMessage]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="form">
			<div className="formFlex">
				<div className="fieldWrapperLight">
					<label htmlFor="subject">I am interested in... *</label>
					<FormSelect
						inputId="subject"
						name="subject"
						options={subjectOptions}
						defaultValue={subjectOptions[0]}
						onChange={handleSelectedSubject}
					/>
					<div className="errorMessageNameWrapper"></div>
				</div>
			</div>
			<div className="formFlex">
				<div className="fieldWrapperLight">
					<label htmlFor="name">Your Full Name *</label>
					<input
						name="name"
						value={selectedData.name || ""} // || "" - an uncontrolled input of type text to be controlled
						onChange={(e) => handleData(e)}
						type="text"
						placeholder="Your name here..."
						autoComplete="off"
						ref={register({ required: true })}
					/>
					<div className="errorMessageNameWrapper" ref={errorMessageNameWrapper}>
						<p className="errorMessage" ref={errorMessageName}>
							{errors.name && <span>This field is required *</span>}
						</p>
					</div>
				</div>
				<div className="fieldWrapperLight">
					<label htmlFor="email">Email Address *</label>
					<input
						name="email"
						value={selectedData.email || ""}
						onChange={(e) => handleData(e)}
						type="email"
						placeholder="Your email address here..."
						autoComplete="off"
						ref={register({ required: true })}
					/>
					<div className="errorMessageEmailWrapper" ref={errorMessageEmailWrapper}>
						<p className="errorMessage" ref={errorMessageEmail}>
							{errors.email && <span>This field is required *</span>}
						</p>
					</div>
				</div>
			</div>
			<div className="formFlex">
				<div className="fieldWrapperLight">
					<label htmlFor="company">Company</label>
					<input
						name="company"
						value={selectedData.company || ""}
						onChange={(e) => handleData(e)}
						type="text"
						placeholder="Company name here..."
						autoComplete="off"
						ref={register}
					/>
					<div className="errorMessageNameWrapper"></div>
				</div>
				<div className="fieldWrapperLight">
					<label htmlFor="location">Location</label>
					<input
						name="location"
						value={selectedData.location || ""}
						onChange={(e) => handleData(e)}
						type="text"
						placeholder="Your location here..."
						autoComplete="off"
						ref={register}
					/>
					<div className="errorMessageNameWrapper"></div>
				</div>
			</div>
			<div className="textareaWrapperLight">
				<label htmlFor="message">Your Message</label>
				<textarea
					name="message"
					value={selectedData.message || ""}
					onChange={(e) => handleData(e)}
					id="message"
					cols="30"
					rows="10"
					ref={register}
				></textarea>
			</div>
			<div className="thankYouMessageWrapper" ref={thankYouMessageWrapper}>
				<span className="successMessage" ref={thankYouMessage}>
					{successMessage}
				</span>
			</div>
			<div className="buttonWrapperLight">
				<button className="button" type="submit">
					Submit Request
				</button>
			</div>
			
		</form>
	);
};

export default Form;
