import React from "react";
import Select from "react-select";

const FormSelect = ({ name, options, onChange, defaultValue, inputId }) => {
	const customStyles = {
		indicatorSeparator: (styles) => ({display:'none'}),
		control: (base, state) => ({
			...base,
			height: 60,
			minHeight: 60,
			paddingLeft: 10,
			background: "#f8f8f8",
			color: "#000",
			borderColor: !state.isFocused ? "#f8f8f8" : "#ebebeb",
			boxShadow: !state.isFocused ? null : "0 0 0 0 #ebebeb, inset 0 0 0 1px #ebebeb",
			transition: "all .2s ease-in-out",
			"&:hover": {
				background: "#fdfdfd",
			},
			fontFamily: "Inter",
			fontSize: "1.4rem",
			fontColor: "#000",
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isSelected ? "black" : "black",
			fontFamily: "Inter",
			fontSize: "1.3rem",
			fontWeight: 700,
			borderRadius: 8,
			padding: 10,
			paddingLeft: 30,
			cursor: "pointer",
			transition: "all .3s ease-in-out",
			"&:hover": {
				opacity: .5
			},
		}),
	};
	return (
		<>
			<Select
				classNamePrefix="customSelect"
				defaultValue={defaultValue}
				inputId={inputId}
				name={name}
				styles={customStyles}
				options={options}
				onChange={onChange}
				isSearchable={false}
				theme={(theme) => ({
					...theme,
					borderRadius: 8,
					colors: {
						...theme.colors,
						primary25: "#fff",
						primary: "#fff",
					},
				})}
			/>
		</>
	);
};

export default FormSelect;
